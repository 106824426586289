<template>
  <v-app>
    <NavigationDrawer v-if="usuario"/>
    <AppBar v-if="usuario"/>
    <v-main>
      <router-view/>
    </v-main>
    
    <!-- <v-footer padless app absolute>
    <v-row no-gutters>
        <v-col class="text-center" cols="12" no-g>
          <v-btn v-for="link in links" :key="link.title" plain color="deep-purple accent-2" class="ma-0" :to="link.to">
          {{ link.title }}
          </v-btn>
        </v-col>
    </v-row>
    </v-footer> -->
    <snackbar />
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import NavigationDrawer from '@/components/layouts/NavigationDrawer'
import AppBar from '@/components/layouts/AppBar'
import snackbar from '@/components/layouts/snackbar'

export default {
  name: 'App',

  data: () => ({
    // drawer: null,
  }),
  components: {
    NavigationDrawer, AppBar, snackbar
  },
  methods: {
    ...mapActions(['logOut', 'initialState'])
  },
  computed: {
    ...mapState(['usuario'])
  },
}
</script>

<style>
.selecteditem {
  background-color: #7a77d0 !important;
}
.theme--light.selecteditem {
  color: rgba(231, 227, 252, 0.87) !important;
}
</style>
