import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use( CKEditor );

import Vue2Editor from "vue2-editor"
Vue.use(Vue2Editor)

// Initialize Firebase
import firebaseConfig from '@/plugins/firebase.js'
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
db.settings({ timestampsInSnapshot: true, merge: true })

Vue.config.productionTip = false

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    store.dispatch('isLogin', {
      email: user.email,
      uid: user.uid
    })
  } else {
    store.dispatch('isLogin', null)
  }
  
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});