<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" :top="'top'" :multi-line="mode === 'multi-line'">
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ icon }}</v-icon>
      <v-layout column>
        <div>
          <strong>{{ title }}</strong>
        </div>
        <div>{{ text }}</div>
      </v-layout>
    </v-layout>
    <template v-slot:action="{ attrs }">
    <v-btn text v-bind="attrs" @click="show = false" icon>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',
  data() {
    return {
      show: false,
      title: "",
      text: "",
      icon: "",
      color: "",
      timeout: 4000,
      mode: "multi-line",
      namespaced: true
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.title = state.snackbar.title;
        this.text = state.snackbar.text;
        this.icon = state.snackbar.icon;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.mode = state.snackbar.mode;
        this.show = true;
      }
    });
  },
}


    // title: '',
    // text: "",
    // icon: '',
    // color: "",
    // timeout: "",
    // mode: "multi-line",
</script>

