<template>
    <v-navigation-drawer app floating v-model="$store.state.drawer">
       <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <h2>Panel</h2>
          </v-list-item-title>
          <v-list-item-subtitle>
            Autodiagnóstico Digital
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <!-- <v-list-item v-for="item in items" :key="item.title" link :to="item.to" :disabled="item.disabled" active-class="selecteditem">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->



        <template v-for="(item, index) in items">
          <v-list-item :key="index" link v-if="typeof item.sub === 'undefined'" :disabled="item.disabled" active-class="selecteditem" :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Si es sub menu  -->
          <v-list-group :key="index" :value="false" v-else no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="subitem in item.sub" :key="subitem.id" link :to="{name: 'Diagnostico', params: {category: subitem.to} }">
              <!-- <v-list-item-title v-text="title"></v-list-item-title> -->
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              <v-list-item-icon>
               <v-icon>{{ subitem.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
</template>



<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'NavigationDrawer',

  data: () => ({
    // mydrawer: 'lol',
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/'},
      { title: 'Contenidos Web', icon: 'mdi-format-float-left', to: '/contenidos' },
      { title: 'Diagnostico', icon: 'mdi-format-list-numbered', to: '',
        sub: [
          { title: 'Soy periodista', icon: 'mdi-fountain-pen', to: 'periodismo' },
          { title: 'Soy defensor(a) de derechos humanos', icon: 'mdi-human-greeting', to: 'defensa' }
        ]

      },
      { title: 'Usuarios', icon: 'mdi-account-multiple', to: '/usuarios', disable: true },
    ],
  }),
  methods: {
    ...mapActions(['logOut', 'initialState'])
  },
  computed: {
    ...mapState(['usuario'])
  },
  watch: {
    group () {
      this.drawer = false
    },
}
};
</script>

<style>
.selecteditem {
  background-color: #7a77d0 !important;
}
.theme--light.selecteditem {
  color: rgba(231, 227, 252, 0.87) !important;
}
</style>