export default {
  namespaced: true,
  state: {
    title: '',
    text: "",
    icon: '',
    color: "",
    timeout: "",
    mode: "multi-line",
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.title = payload.title
      state.text = payload.text
      state.color = payload.color
      state.icon = payload.icon
      state.timeout = payload.timeout
      state.mode = payload.mode
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
  },
}



// snackbar: {
//   active: false,

// }