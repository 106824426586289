import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import snackbar from '@/store/modules/snackbar.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackbar
  },
  state: {
    // Sesion de usuario datos
    usuario: null,
    error: '',

    // layout
    drawer: null,
    isLoading: false,

    //seccion editar datos
    currentPerfil: '',
    currentModulo: '',
    currentComponente: 'ListEdit',
  },
  mutations: {
    setUsuario(state, usuario) {
      state.usuario = usuario
    },
    setError(state, error) {
      state.error = error
    },

    
    // currentChange(state,data) {
    //   state[data.toChange] = data.value
    // },
    currentChange(state,data) {
      data.forEach(dato => {
        state[dato.toChange] = dato.value
      })
    },
  },
  actions: {
    logIn({commit}, usuario) {
      commit('setError', null)
      firebase.auth().signInWithEmailAndPassword(usuario.email, usuario.password).then(
        res => {
          let userData = {
            email: res.user.email,
            uid: res.user.uid
          }
          commit('setUsuario', userData)
          router.push({ name: 'Dashboard' })
        }, err => {
          commit('setError', err.message)
        }
      )
    },
    isLogin({commit}, usuario) {
      if (usuario == null) {
        commit('setUsuario', null)
      } else {
        let userData = {
          email: usuario.email,
          uid: usuario.uid
        }
        commit('setUsuario', userData)
      }
    },
    logOut({commit}) {
      firebase.auth().signOut()
      .then(() => {
        commit('setUsuario', null)
        router.push({ name: 'Login' })
      })
      .catch((err) => {
        console.log(err)
      })
    },

    initialState({commit}) {
      commit('currentChange', [
        {toChange: 'currentPerfil', value: null},
        {toChange: 'currentModulo', value: null},
        {toChange: 'currentComponente', value: 'ListEdit'},
        {toChange: 'currentComponente', value: null}

      ])
    }
  },
})
