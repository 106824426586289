<template>
  <v-app-bar app flat dense>
      <v-app-bar-nav-icon @click.stop="$store.state.drawer = !$store.state.drawer"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title @click="initialState">Mi Test Digital <v-icon>mdi-medical-bag</v-icon> <small>v 0.1</small></v-toolbar-title> -->
    
    <v-spacer></v-spacer>

    <v-checkbox class="pt-6" v-model="$vuetify.theme.dark" color="light" off-icon="mdi-theme-light-dark" on-icon="mdi-theme-light-dark"></v-checkbox>
 
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-if="usuario" v-bind="attrs" v-on="on">
          <v-icon @click="logOut(), initialState()" color="blue-greydarken-2">mdi-logout-variant</v-icon>
        </v-btn>
      </template>
      <span>Salir</span>
    </v-tooltip>
  </v-app-bar>
</template>


<script>
import {mapActions, mapState, mapMutations} from 'vuex'
export default {
  name: 'AppBar',

  data: () => ({
    // drawer: null,
    message: 'dd',
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/'},
      { title: 'Contenidos', icon: 'mdi-format-float-left', to: '/contenidos' },
      { title: 'Diagnostico', icon: 'mdi-format-list-numbered', to: '/diagnostico'},
      { title: 'Usuarios', icon: 'mdi-account-multiple', to: '/usuarios', disable: true },
    ],
    links: [
      // { title: 'Inicio', icon: 'mdi-view-dashboard', to: '/' },
      // { title: 'Autodiagnóstico', icon: 'mdi-medical-bag', to: '/autodiagnostico' },
      { title: 'Página de inicio', icon: 'mdi-home', to: '/EditInicio' },
      { title: 'Metodología', icon: 'mdi-school', to: '/contentedit/metodologia' },
      { title: 'Acerca de', icon: 'mdi-help-box', to: '/contentedit/acerca' },
      { title: 'Política y manejo de datos', icon: 'mdi-book-open-page-variant', to: '/contentedit/politica' },
    ],
  }),
  methods: {
    ...mapActions(['logOut', 'initialState']),
    ...mapMutations(['currentChange']),
  },
  computed: {
    ...mapState(['usuario'])
  }
};
</script>

<style>
.selecteditem {
  background-color: #7a77d0 !important;
}
.theme--light.selecteditem {
  color: rgba(231, 227, 252, 0.87) !important;
}
</style>