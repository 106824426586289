import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/contenidos',
    name: 'Contenidos',
    component: () => import('../views/Contenidos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import('../views/Usuarios.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },

  /*  Rutas dinamicas DIAGNOSTICO  */
  {
    path: '/:category',
    name: 'Diagnostico',
    component: () => import('../views/Diagnostico.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/:category/:section',
    name: 'DiagnosticoSec',
    component: () => import('../views/Diagnostico.vue'),
    meta: {
      requiresAuth: true,
      windowRedirectAfter: true,
    }
  },
  {
    path: '/:category/:section/:id',
    name: 'DiagnosticoSecAgre',
    component: () => import('../views/Diagnostico.vue'),
    meta: {
      requiresAuth: true,
      windowRedirectAfter: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // eslint-disable-next-line no-unused-vars
  return originalPush.call(this, location).catch((error) => {
    // console.log(error)
  });
};

router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some(record => record.meta.requiresAuth)
  const usuario = firebase.auth().currentUser

  


  if (rutaProtegida === true && usuario === null) {
    next({name: 'Login'})
  } else {
    next()
  }
})


export default router
